import { getLicensesCount } from '@core/interfaces/licenses.interface';
import { MaintenanceStatus, SecurityLevels } from '../constants';
import { ICompanyPoolConfig } from '../interfaces';

interface ITimeInterface {
  hours: number;
  minutes: number;
}

export interface ICompanyConfig {
  dateformat: string;
  timezone: string;
  timeformat: string;
  securityLevel: SecurityLevels;
  autoLogout: ITimeInterface;
  maxPasswordAttempts: number;
  passwordExpirationTime: number;
  videoStreaming: boolean;
  reviewIdleTime: number;
  bedrockIntegration: boolean;
  qualityDashboard: boolean;
  validationProjectDashboard: boolean;
  individualKPI: boolean;
}

// tslint:disable:variable-name
export class CompanyDTO {
  uuid: string;
  name: string;
  description: string;
  domain: string;
  is_active: boolean;
  image: string;
  size: string;
  website: string;
  pool_config: ICompanyPoolConfig;
}

export class CompanyConfigDTO {
  date_format: string;
  time_format: string;
  timezone: string;
  security_password_expiration: number;
  security_auto_log_out: number;
  security_level: string;
  security_password_attempts: number;
  company_uuid: string;
  video_streaming?: boolean;
  review_idle_time: number;
  bedrock_integration?: boolean;
  quality_dashboard?: boolean;
  validation_project_dashboard?: boolean;
  individual_kpi?: boolean;
}

export class Company {
  uuid: string;
  name: string;
  description: string;
  domain: string;
  website: string;
  config: ICompanyConfig;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  relationship_created_at: Date;
  child_companies: Company[];
  parent_companies: Company[];
  image_url: string;
  image: string;
  is_published: boolean;
  size: string;
  maintenance_status: MaintenanceStatus;
  is_sso: boolean;
  pool_config: ICompanyPoolConfig;
  matrix?: string;
  licensesCount?: number;
  // convert minutes to hours and minutes
  static getHoursAndMinutes(num): ITimeInterface {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return { hours, minutes };
  }

  constructor(companyData) {
    this.uuid = companyData.uuid;
    this.name = companyData.name;
    this.description = companyData.description;
    this.domain = companyData.domain;
    this.is_active = companyData.is_active || 0;
    this.is_published = companyData.is_published || 0;
    this.website = companyData.website;
    this.created_at = companyData.created_at;
    this.updated_at = companyData.updated_at;
    this.image_url = companyData.image_url;
    this.image = companyData.image;
    this.size = companyData.size;
    this.maintenance_status = companyData.maintenance_status;
    this.setConfig(companyData.config);
    this.child_companies = (companyData.child_companies || []).map(comp => new Company(comp));
    this.parent_companies = (companyData.parent_companies || []).map(comp => new Company(comp));
    this.relationship_created_at = companyData.relationship_created_at;
    this.is_sso = companyData.is_sso;
    this.pool_config = companyData.pool_config;
    this.licensesCount = getLicensesCount(companyData.licenses)
  }

  public toDTO(): CompanyDTO {
    return {
      uuid: this.uuid,
      name: this.name,
      description: this.description,
      domain: this.domain,
      is_active: this.is_active,
      image: this.image,
      size: this.size,
      website: this.website ? ['http', 'https'].includes(this.website.split('://')[0]) ?
        this.website : 'http://' + this.website : null,
      pool_config: this.pool_config
    };
  }

  public setConfig(configData?) {
    if (configData) {
      this.config = {
        dateformat: configData.date_format,
        timezone: configData.timezone,
        timeformat: configData.time_format,
        securityLevel: configData.security_level,
        autoLogout: Company.getHoursAndMinutes(configData.security_auto_log_out),
        maxPasswordAttempts: configData.security_password_attempts,
        passwordExpirationTime: configData.security_password_expiration,
        videoStreaming: configData.video_streaming,
        reviewIdleTime: configData.review_idle_time || 1,
        bedrockIntegration: configData.bedrock_integration,
        qualityDashboard: configData.quality_dashboard,
        validationProjectDashboard: configData.validation_project_dashboard,
        individualKPI: configData.individual_kpi,
      };
    } else {
      this.config = {
        dateformat: 'M/D/YYYY',
        timezone: 'America/New_York',
        timeformat: 'H:mm',
        securityLevel: SecurityLevels.HIGH,
        autoLogout: {
          hours: 1,
          minutes: 30
        },
        maxPasswordAttempts: 3,
        passwordExpirationTime: 1,
        videoStreaming: false,
        reviewIdleTime: 1,
        bedrockIntegration: false,
        qualityDashboard: false,
        validationProjectDashboard: false,
        individualKPI: false
      };
    }
  }

  public toCompanyConfigDTO(isBTRAdmin = false): CompanyConfigDTO {
    const returnObj: CompanyConfigDTO = {
      date_format: this.config.dateformat,
      time_format: this.config.timeformat,
      timezone: this.config.timezone,
      security_password_expiration: this.config.passwordExpirationTime,
      security_auto_log_out: this.config.autoLogout.hours * 60 + this.config.autoLogout.minutes,
      security_level: SecurityLevels[this.config.securityLevel],
      security_password_attempts: this.config.maxPasswordAttempts,
      company_uuid: this.uuid,
      review_idle_time: this.config.reviewIdleTime
    };
    
    if(isBTRAdmin){
      returnObj.video_streaming = this.config.videoStreaming;
    }
    if(isBTRAdmin){
      returnObj.bedrock_integration = this.config.bedrockIntegration;
      returnObj.quality_dashboard = this.config.qualityDashboard;
      returnObj.validation_project_dashboard = this.config.validationProjectDashboard;
      returnObj.individual_kpi = this.config.individualKPI;
    }

    return returnObj;
  }
}
