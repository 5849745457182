import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CompanySelectors } from '@store/company/company.selectors';
import { fromEvent, merge, Subject, Subscription, throttleTime, interval, take, filter } from 'rxjs';
import { ExtendLock, SetLockValue } from 'src/app/documents/store/document.actions';

@Injectable({
  providedIn: 'root'
})
export class SdoxReviewsIdleService {

  private timerTime;
  private resetIdleFromUser$: Subscription;

  private idleTimeExpired$: Subject<void> = new Subject<void>();

  private idleTimeout$: Subscription;
  private expiredTimeout$: Subscription;
  private checkForExtend$: Subscription;
  private userActivityPresent = false;

  /**
   * Will return the timer for idle timeout (when user opens a new message)
   */
  get idleTimeExpired() {
    return this.idleTimeExpired$.asObservable();
  }

  constructor(private store: Store) {
    this.store.select(CompanySelectors.getCurrentCompany)
      .pipe(filter(company => !!company?.uuid)).subscribe(company => {
        this.timerTime = company.config.reviewIdleTime * 60 * 1000; // reviewIdleTime is in minutes, will transform it in seconds and milliseconds
      });
  }

  /**
   * Called for each Lock or Extend calls are made
   */
  public startIdleTimer() {
    if (!this.resetIdleFromUser$ || this.resetIdleFromUser$.closed) {
      this.listenForUserActivity();
    }
    this.idleTimeout$?.unsubscribe();
    const timer = this.timerTime - 5000; // Subtract 5 seconds to make sure the lock resource is still on server 
    this.idleTimeout$ = interval(timer)
      .pipe(take(1))
      .subscribe(() => {
        this.idleTimeExpired$.next();
        this.idleTimeout$.unsubscribe();
      });
  }

  /**
   * Called to stop the idle timer (usually when Unlock is fired)
   */
  public stopIdleTimer() {
    this.idleTimeout$?.unsubscribe();
    this.resetIdleFromUser$?.unsubscribe();
  }

  /**
   * Called for each Lock or Extend calls coming from sockets and blocking the UI
   * Will set lockTimerExpired based on lock status expireDate
   * This should be used when an users gets it's UI blocked as a backup for unlocking in case UnLock event is not received
   * @param expireDate 
   */
  public startLockStatusExpire(expireDate: Date) {
    this.userActivityPresent = false;
    const timer = expireDate.getTime() - (new Date()).getTime();
    this.expiredTimeout$?.unsubscribe();
    this.expiredTimeout$ = interval(timer)
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new SetLockValue(null));
        this.expiredTimeout$.unsubscribe();
      })


    this.checkForExtend$?.unsubscribe();
    this.checkForExtend$ = interval(timer - 5000)
      .pipe(take(1))
      .subscribe(() => {
        if (this.userActivityPresent) {
          this.store.dispatch(new ExtendLock(true));
        }
      });
  }

  /**
   * Called to stop expiredTimeout
   */
  public stopLockStatusExpire() {
    this.expiredTimeout$?.unsubscribe();
    this.checkForExtend$?.unsubscribe();
  }

  /**
   * Called internally to refresh timer on user activity
   */
  private listenForUserActivity() {
    if (this.resetIdleFromUser$) {
      this.resetIdleFromUser$.unsubscribe();
    }
    this.resetIdleFromUser$ = merge(
      fromEvent(window, 'scroll'),
      fromEvent(window, 'keypress'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'wheel'),
      fromEvent(window, 'click')
    ).pipe(throttleTime(1000))
      .subscribe(() => {
        this.userActivityPresent = true;
        this.startIdleTimer();
      });
  }
}

