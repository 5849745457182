import { DatepickerRange } from '@shared/components/datepicker/datepicker.models';

export interface IFilterDataValues {
  search?: string;
  is_sso?: string;
  filters?: IFiltersValues;
  date_filters?: IDateFilters;
  sortBy?: string;
  sortDirection?: string;
  access_level?: 'access' | 'no_access' | 'workspace_access' | 'no_workspace_access' | 'all';
  cards?: string[];
}

export interface IFiltersValues {
  type?: string[];
  sdox_document_category?: string[];
  status?: string[];
  owner_user_uuid?: string[];
  user_uuid?: string[];
  system_uuid?: string[];
  company_uuid?: string[];
  is_private?: string[];
  is_downloaded?: string[] | boolean;
  filter_tag_value_uuid?: string[];
  ecm_exclude_switch?: boolean;
  exclude?: string[];
  group_by?: string;
  is_sso?: number;
  is_company_admin?: number;
  show_only_pending_sso?: boolean;
  is_active?: number;
  exclude_current_user?: boolean;
  prefix?: string[];
  all_versions?: boolean;
  all_versions_except_tse_and_tm?: boolean;
  created_at?: { from: string; to: string };
  category?: string[];
  access_type?: string;
  updated_at?: { from: string; to: string };
  target_date_status?: string;
}

export interface IDateFilters {
  created_at?: IDateFiltersRange;
  updated_at?: IDateFiltersRange;
  published_at?: IDateFiltersRange;
  downloaded_at?: IDateFiltersRange;
  period?: IDateFiltersRange;
}

export interface IDateFiltersRange {
  from?: string;
  to?: string;
  selected?: string;
  selectValues?: IFilterSelectValue[];
}

export interface IFilterSelectValue {
  value?: any;
  label?: string;
}

export interface IFilterSortSelectValue extends IFilterSelectValue {
  sortDirection: string;
}

export interface FilterModel<K> {
  label?: string;
  selected: K;
  displayFn?: (item?) => string;
  displayInfoFn?: (item?) => string;
  displaySecondaryInfoFn?: (item?) => string;
  displayThirdInfoFn?: (item?) => string;
  shouldGetSelectedValueFromArray?: boolean;
  children?: IFilterSelectValue[];
  data?: any;
  parentKey?: string;
  isMultiselect?: boolean;
  hiddenFromView?: boolean;
}

export interface FilterModelArray<K, T> extends FilterModel<K> {
  selectValues?: T[];
  secondarySelectValues?: T[];
  secondarySelected?: K;
}

export interface CommonDateFiltersModel {
  created_at?: FilterModel<DatepickerRange>;
  target_date?: FilterModel<DatepickerRange>;
  completion_date?: FilterModel<DatepickerRange>;
  updated_at?: FilterModel<DatepickerRange>;
  published_at?: FilterModel<DatepickerRange>;
  downloaded_at?: FilterModel<DatepickerRange>;
  period?: FilterModel<DatepickerRange>;
}

export interface CommonFiltersItemsModel<T> {
  search?: FilterModel<string>;
  is_sso?: FilterModel<string>;
  is_utc?: boolean;
  filters?: T;
  date_filters?: CommonDateFiltersModel;
  sortOptions?: FilterModelArray<string, IFilterSortSelectValue>;
}

export interface CommonFiltersModel<T> {
  activeFilters?: any[];
  filterItems: CommonFiltersItemsModel<T>;
  loading?: boolean;
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc'
}

export interface ActiveFilter {
  key: string;
  value: FilterModel<any> | FilterModelArray<any, any>;
}


export interface DropdownFilterItem {
  key: string;
  label: string;
  disabled: boolean;
  options?: any[];
}

export interface IFilterDataValuesReports extends IFilterDataValues {
  report_uuid: string;
}