import { IFilterSelectValue } from './filter.interface';

export type TaskType = 'all' | 'upcoming' | 'overdue';

export interface ITaskFilter extends IFilterSelectValue {
  count?: number;
  items?: MyTaskInterface[];
}

export interface MyTaskInterface {
  uuid: string;
  type: string;
  comment?: string;
  assignation_date: string;
  target_date: string;
  target_date_status: string;
  status: string;
  status_label: string;
  workflow_uuid: string;
  workflow_root_uuid: string;
  workflow_title: string;
  workflow_display_label: string;
  workflow_version: number;
  target_date_status_label: string;
  workflow_document_category: string;
}

export interface MyTasksResponseInterface {
  data: MyTaskInterface[];
  total: number;
}


export enum ShortcutFilter {
  All = 'all',
  Overdue = 'overdue',
  Upcoming = 'upcoming',
}


export interface MyAssignmentsInterface {
  uuid: string;
  type: string;
  workflow: any;
  system: any;
  assigner: any;
  assignation_date: string;
  workflow_display_label: string;
  workflow_version: string;
  workflow_title: string;
  workflow_status_label: string;
  system_name: string;
  assigner_first_name: string;
  assigner_last_name: string;
}
export interface MyAssignmentsResponseInterface {
  data: MyAssignmentsInterface[];
  total: number;
}